import React from 'react';
import Img from 'gatsby-image';

/**
 * This component provides appropriate image components, based on the following:
 * - if there is no childImageSharp provided by gatsby-image, it falls back to HTML img
 * - otherwise it uses the Img component from gatsby-image
 */

const FallbackImg = ({ className, image, alt }) => {
  if (!image) {
    return null;
  }

  if (!image.childImageSharp) {
    return <img className={className} src={image.publicURL} alt={alt} />;
  }

  return <Img className={className}
          alt={alt}
          fluid={image.childImageSharp.fluid}
          fixed={image.childImageSharp.fixed} />;
};

export default FallbackImg;
