import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import FallbackImg from "./FallbackImg";
import Img from "gatsby-image";
import { Parallax } from "react-parallax";
import { Button, Container } from "react-bootstrap";
import { graphql, Link, StaticQuery } from "gatsby";
import appendQueryString from "../utils/query";
import CallToActionButton from "./CallToActionButton";

const primaryColour = "#4CAB76";
const secondaryColour = "#FBA919";

const HeroCard = ({
  image,
  size,
  title,
  subtitle,
  quote,
  quoteAuthor,
  actionBtnText,
  actionBtnUrl,
}) => (
  <StaticQuery
    query={graphql`
      query {
        file(extension: { eq: "png" }, name: { eq: "logo" }) {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
          extension
          publicURL
        }
      }
    `}
    render={(data) => {
      return (
        <div>
          <Card className={`hero ${size}`}>
            {image && (
              <Img
                fluid={image.childImageSharp.fluid}
                alt="Hero image"
                style={{ height: "inherit", width: "100%" }}
                imgStyle={{ objectFit: "cover" }}
              />
            )}
            <Card.ImgOverlay className="hero-text-container">
              <Container className="content">
                <div>
                  {title && <h1>{title}</h1>}
                  {subtitle && <h5>{subtitle}</h5>}
                  {quote && (
                    <blockquote>
                      “{quote}”{quoteAuthor && <cite>{quoteAuthor}</cite>}
                    </blockquote>
                  )}
                </div>
                {actionBtnText && actionBtnUrl && (
                  <CallToActionButton
                    link={actionBtnUrl}
                    text={actionBtnText}
                    btnClass="btn btn-secondary" />
                )}
              </Container>
            </Card.ImgOverlay>
          </Card>
        </div>
      );
    }}
  />
);

HeroCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.any,
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["large", "medium", "small"]),
  quote: PropTypes.string,
  titleColour: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleColour: PropTypes.string,
  textAlignment: PropTypes.string,
  textSpacing: PropTypes.string,
  actionBtnText: PropTypes.string,
  actionBtnUrl: PropTypes.string,
};

HeroCard.defaultProps = {
  title: null,
  size: "small",
  titleColour: primaryColour,
  subtitle: null,
  subtitleColour: secondaryColour,
  textAlignment: "left",
  textSpacing: "mt-2",
};

export default HeroCard;
